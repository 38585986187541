import React from "react";
const SentryTest = () => {
  const handleClick = () => {
    throw new Error("Sentry Test Error");
  };

  return (
    <div>
      <h1>Sentry Test</h1>
      <button onClick={handleClick}>Click me to throw an error</button>
    </div>
  );
};

export default SentryTest;
