import React from "react";
import { COMPANY_EMAIL, COMPANY_NAME } from "./constants";

function Privacy() {
  return (
    <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
      <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
        <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Privacybeleid
          </h1>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            1. Inleiding
          </h2>
          <p className="text-gray-700 mb-3">
            Welkom bij {COMPANY_NAME}. Wij zijn toegewijd aan de bescherming van
            de privacy van onze gebruikers en abonnees. Dit Privacybeleid
            beschrijft hoe wij uw persoonlijke gegevens verzamelen, gebruiken en
            beschermen.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            2. Verzamelde Gegevens
          </h2>
          <p className="text-gray-700 mb-3">
            Wij verzamelen alleen de noodzakelijke informatie die u ons
            verstrekt. Dit omvat:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li className="text-gray-700 mb-2">
              E-mailadres: Wanneer u zich abonneert op onze nieuwsbrief, vragen
              wij u om uw e-mailadres.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            3. Gebruik van Gegevens
          </h2>
          <p className="text-gray-700 mb-3">
            Uw e-mailadres wordt uitsluitend gebruikt voor het volgende doel:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li className="text-gray-700 mb-2">
              Het verzenden van onze nieuwsbrief: We gebruiken uw e-mailadres om
              u updates, aankondigingen en exclusieve kortingen van{" "}
              {COMPANY_NAME} te sturen.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            4. Opslag en Beveiliging van Gegevens
          </h2>
          <p className="text-gray-700 mb-3">
            Uw e-mailadres wordt opgeslagen in onze beveiligde database. We
            nemen de volgende maatregelen om uw gegevens te beschermen:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li className="text-gray-700 mb-2">Beveiligde servers</li>
            <li className="text-gray-700 mb-2">Versleutelde communicatie</li>
            <li className="text-gray-700 mb-2">
              Toepassen van security best practices in het design van ons
              software
            </li>
            {/* <li className="text-gray-700 mb-2">Regelmatige beveiligingsaudits</li> */}
          </ul>
          <p className="text-gray-700 mb-3">
            Wij zullen uw e-mailadres niet delen met derden, tenzij wettelijk
            vereist is.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            5. Uw Rechten
          </h2>
          <p className="text-gray-700 mb-3">
            U hebt het volgende recht met betrekking tot uw gegevens:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li className="text-gray-700 mb-2">
              Het recht op inzage: U kunt op elk moment informatie opvragen over
              de gegevens die wij over u hebben.
            </li>
            <li className="text-gray-700 mb-2">
              Het recht op rectificatie: U kunt ons verzoeken eventuele onjuiste
              gegevens te corrigeren.
            </li>
            <li className="text-gray-700 mb-2">
              Het recht op verwijdering: U kunt ons vragen om uw gegevens te
              verwijderen.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            6. Contact
          </h2>
          <p className="text-gray-700 mb-3">
            Als u vragen heeft over dit Privacybeleid, neem dan contact met ons
            op via {COMPANY_EMAIL}.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            7. Wijzigingen in het Privacybeleid
          </h2>
          <p className="text-gray-700 mb-3">
            Dit beleid kan van tijd tot tijd worden bijgewerkt. Wij adviseren u
            om deze pagina regelmatig te bezoeken voor eventuele wijzigingen.
          </p>
        </article>
      </div>
    </main>
  );
}

export default Privacy;
