import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ExternalLinkIcon from "./icons/ExternalLinkIcon";
import { useApiService } from "./hooks/apiService";
import useUserStore from "./hooks/user-store";
import emailToGravatar from "./utils/gravatar";

const DashboardHome = () => {
  const [leads, setLeads] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { apiService } = useApiService();
  const chatbotUUID = useUserStore((state) => state?.userData?.chatbot?.uuid);

  async function getLeadsData() {
    if (chatbotUUID !== "") {
      setLoading(true);
      let data = await apiService.getLeads(chatbotUUID, 1);
      setLeads(data.slice(0, 5));
      setLoading(false);
    }
  }

  useEffect(() => {
    getLeadsData();
  }, [chatbotUUID]);

  return (
    <div className="flex flex-col justify-center w-full h-full space-y-4">
      <div className="w-full max-w-md p-4 bg-white border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <div className="flex items-center justify-between mb-4">
          <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
            Latest Leads
          </h5>
          <Link
            to="/dashboard/leads"
            className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
          >
            View all
          </Link>
        </div>
        <div className="flow-root">
          {loading && <p>Loading...</p>}
          {leads.length === 0 && !loading && (
            <p>
              Helaas zijn er nog geen leads! Plaats uw chatbot op een prominente
              pagina op uw website en zorg dat uw marketing kanalen goed
              ingeregeld zijn zodat uw chatbot de maximale aandacht krijgt die
              het verdiend!
            </p>
          )}
          <ul
            role="list"
            className="divide-y divide-gray-200 dark:divide-gray-700"
          >
            {leads.map((lead, index) => (
              // last li should have pt-3 pb-0 sm:pt-4
              <li
                key={index}
                className={
                  index === leads.length - 1
                    ? "pt-3 pb-0 sm:pt-4"
                    : "py-3 sm:py-4"
                }
              >
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="w-8 h-8 rounded-full"
                      src={emailToGravatar(lead.chat_user.username)}
                      alt={`Profile picture of ${lead.name}`}
                    ></img>
                  </div>
                  <div className="flex-1 min-w-0 ms-4">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                      {lead.chat_user.username}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                      {lead.chat_user.username}
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    {lead.site_configs?.length > 0 && (
                      <a
                        href={lead.site_configs[0].site_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline text-blue-600 dark:text-blue-500"
                        title={lead.site_configs[0].site_url}
                      >
                        <ExternalLinkIcon />
                      </a>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
